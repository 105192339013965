<template>
  <div class="privacy">
    <div class="legal-title">
      Politique de Protection des Données
    </div>

    <HCard
        free
        class="legal-block"
    >
      <div class="block-text">
        <p>La présente politique est applicable aux traitements de données à caractère personnel opérés sur la
          plateforme
          “Happydom”. La plateforme Happydom a pour but de :</p>
        <p>- mettre en relation les aidants et les établissements d’hébergement pour personnes âgées dépendantes
          (“EHPAD”),<br/>
          - mettre en relation les aidants et les vendeurs tiers de la marketplace.</p>

        <div class="block-title">
          1. Qui est responsable du traitement de vos
          données personnelles ?
        </div>

        <p>Vos données sont traitées conjointement par HDC Santé (“nous” ou “Happytal”), société par actions simplifiée,
          immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 790 294 615, dont le siège social
          est situé au 59-61 bis, rue Pernety, 75014 Paris, opératrice de la plateforme Happydom, et l’EHPAD au sein
          duquel la ou les personnes dont vous êtes l’aidant sont hébergées. Vous trouverez les coordonnées de l’EHPAD
          sur son site internet. HDC Santé opère son activité sous le nom commercial Happytal.</p>

        <p>Par ailleurs, lorsque vous utilisez la plateforme pour passer commande auprès d’un de nos partenaires (un
          vendeur tiers), ce partenaire est co-responsable du traitement opéré pour la gestion de votre commande. Vous
          trouverez les coordonnées de chaque vendeur tiers sur notre marketplace, via la fiche du produit qui vous
          intéresse.</p>

        <div class="block-title">
          2. Quelles données utilisons-nous ?
        </div>

        <p>Nous utilisons exclusivement les données suivantes :</p>
        <p>- vos données d’identification (nom, prénom, civilité),<br/>
          - vos coordonnées (adresse email, numéro de téléphone),<br/>
          - l’identité des résidents dont vous avez la charge,<br/>
          - le contenu des messages que vous échangez avec l’EHPAD,<br/>
          - le contenu des éventuelles commandes que vous passez sur la plateforme,<br/>
          - vos données de connexion (qui nous permettent d’assurer la sécurité de la plateforme),<br/>
          - les messages relatifs à l’utilisation de la plateforme que vous pouvez échangés avec nos équipes techniques.
        </p>

        <div class="block-title">
          3. Pourquoi avons-nous besoin de vos
          données personnelles (pour quelles finalités) ?
        </div>

        <p>Le traitement de vos données poursuit plusieurs objectifs (“finalités”). L’ensemble de ces finalités remplit
          au moins une des conditions (“bases juridiques”) définies par le RGPD comme justifiant un traitement de
          données personnelles.</p>

        <table>
          <tr>
            <th>Finalités</th>
            <th>Bases légales</th>
          </tr>
          <tr>
            <td>Création de votre compte et invitation à créer votre mot de passe (invitation envoyée par mail)</td>
            <td>Cette finalité est nécessaire pour vous proposer des modalités de communication améliorées afin de mieux
              servir les intérêts des résidents de l’établissement, qui relève de l’intérêt légitime de l’EHPAD.
            </td>
          </tr>
          <tr>
            <td>Echange d’informations avec les aidants</td>
            <td>Cette finalité est nécessaire pour vous adresser des informations générales ou des alertes spécifiques
              dans le but de mieux servir les intérêts des résidents de l’établissement, qui relèvent de l’intérêt
              légitime de l’EHPAD et de l’application des CGU de la plateforme.
            </td>
          </tr>
          <tr>
            <td>Accompagnement des aidants dans l’utilisation de la plateforme (ex : appel téléphonique d’accompagnement
              au traitement des suggestions)
            </td>
            <td>Cette finalité est nécessaire pour s’assurer que vous puissiez prendre en main le service et tirer
              partie de ses fonctionnalités afin d’améliorer la communication entre l’EHPAD et les aidants, qui relève
              de l’intérêt légitime de l’EHPAD.
            </td>
          </tr>
          <tr>
            <td>Gestion, sécurisation, administration de la plateforme, et gestion des comptes des utilisateurs</td>
            <td>Cette finalité est nécessaire pour assurer le bon fonctionnement et la sécurité de son service, qui
              relèvent de nos intérêts légitimes.
            </td>
          </tr>
          <tr>
            <td>Réalisation d’enquête de satisfaction</td>
            <td>
              <p>Cette finalité est nécessaire à l’amélioration de nos services et à la détection et au traitement des
                insatisfactions de nos clients (résidents et aidants), qui relèvent de nos intérêts légitimes.</p>
              Vous consentez par ailleurs à ce traitement en répondant à nos enquêtes.
            </td>
          </tr>
          <tr>
            <td>Élaboration des statistiques</td>
            <td>Cette finalité est nécessaire au suivi de notre performance commerciale et à l’amélioration de son
              catalogue, qui relèvent de nos intérêts légitimes.
            </td>
          </tr>
          <tr>
            <td>Gestion des demandes et des réclamations - gestion des contentieux</td>
            <td>Cette finalité est nécessaire à l’exécution de votre contrat (commande).<br/>
              Elle est par ailleurs nécessaire à la gestion des éventuels litiges, qui relève de notre intérêt légitime.
            </td>
          </tr>
          <tr>
            <td>Gestion de la facturation et des paiements</td>
            <td>Cette finalité est nécessaire à l’exécution de votre contrat (commande) et au respect de nos obligations
              légales en matière de comptabilité.
            </td>
          </tr>
          <tr>
            <td>Recueil et transmission des commandes au vendeur</td>
            <td>Cette finalité est nécessaire à l’exécution de votre contrat (commande).</td>
          </tr>
          <tr>
            <td>Envoi de la newsletter de notre marketplace</td>
            <td>Cette finalité est nécessaire au maintien du lien avec les clients afin de les informer de l’évolution
              de notre catalogue, ce qui relève de notre intérêt légitime.
            </td>
          </tr>
        </table>

        <div class="block-title">
          4. Combien de temps vos données sont-elles conservées ?
        </div>

        <p>Nous ne conservons vos données personnelles que le temps nécessaire aux
          finalités pour lesquelles elles sont utilisées, et dans le respect de
          la réglementation en vigueur :</p>

        <p>- Nous conservons les données liées à vos achats pendant cinq ans à compter de la passation de votre
          commande, en application des règles de prescription en matière contractuelle, puis nous les archivons
          pour une durée supplémentaire de cinq ans, en application des règles de prescription en matière
          comptable.</p>

        <p>- Si vous réglez votre commande par carte bancaire, vos données de paiement sont conservées par notre
          prestataire de paiement pendant treize mois, en application des délais de prescription applicables en
          matière bancaire.</p>

        <p>- Vos données de connexion sur happydom sont conservées pendant six mois.</p>

        <p>- Les comptes aidants et le contenu de leurs échanges avec les EHPAD sont conservés pendant deux ans
          à compter de la fin de la résidence du résident, dont il a la charge.</p>

        <div class="block-title">
          5. A qui vos données sont-elles transmises ?
        </div>

        <p>Vos données sont communiquées aux catégories de destinataires suivants :</p>

        <p>- Les équipes de l’EHPAD en charge du suivi des résidents et des suggestions et des relations avec les
          aidants,<br/>
          - Les équipes d’Happytal en charge du suivi de la satisfaction, de l'accompagnement des aidants et des
          réclamations ;<br/>
          - Les équipes informatiques d’Happytal et ses prestataires, en charge de l’administration de la
          plateforme;<br/>
          - Les vendeurs de vos éventuelles commandes ;</p>

        <p>Nos services sont hébergés en Union Européenne. Certains de nos partenaires ou prestataires techniques
          peuvent toutefois être amenés à transférer des données vers des pays tiers. Dans ce cas, le transfert de vos
          données est réalisé sur la base d’une décision d’adéquation ou de conditions contractuelles négociées.</p>

        <div class="block-title">
          6. Quels sont vos droits ?
        </div>

        <p>Vous disposez des droits suivants :</p>

        <table>
          <tr>
            <td><b>Droit de rectification</b></td>
            <td>Vous pouvez nous demander la correction des données personnelles que nous traitons vous concernant, si
              elles sont inexactes.
            </td>
          </tr>
          <tr>
            <td><b>Droit de limitation</b></td>
            <td>Vous pouvez nous demander le « gel » de vos données (celles-ci sont conservées mais ne sont plus
              utilisées), dans les cas suivants :<br/>
              - notre traitement n’est pas conforme aux principes de protection des données mais vous ne souhaitez pas
              que nous les effacions,<br/>
              - vos données sont inexactes (gel le temps de leur correction).
            </td>
          </tr>
          <tr>
            <td><b>Droit d’effacement</b></td>
            <td>Vous pouvez nous demander de supprimer vos données, sous réserve des délais de conservation légaux et de
              nos intérêts impératifs.
            </td>
          </tr>
          <tr>
            <td><b>Droit d’accès</b></td>
            <td>Vous pouvez nous demander une copie de toutes les données personnelles dont nous disposons vous
              concernant.
            </td>
          </tr>
          <tr>
            <td><b>Droit d’opposition</b></td>
            <td>Vous pouvez, au moment de - ou après - la passation de votre commande vous opposer à votre inscription à
              notre newsletter.
              Vous pouvez aussi, si vous justifiez de raisons tenant à votre situation particulière, nous demander de
              cesser d’utiliser vos données pour les traitements fondés sur notre intérêt légitime ou l’intérêt légitime
              de l’EHPAD (cf question 3). Dans le cas où, suite à cette opposition, nous ne serions plus en mesure de
              gérer votre compte, celui-ci sera supprimé.
            </td>
          </tr>
          <tr>
            <td><b>Droit à la portabilité</b></td>
            <td>Vous pouvez nous demander de vous transmettre, dans un format couramment utilisé et lisible par la
              machine, l’ensemble des données structurées que vous nous avez confiées pour l’exécution de votre commande
              ou la création de votre espace client sur happydom.
            </td>
          </tr>
          <tr>
            <td><b>Retrait du consentement</b></td>
            <td>Vous pouvez également retirer votre consentement à l’utilisation des réponses que vous avez apportées à
              l’un de nos questionnaires de satisfaction. Nous procéderons alors à sa suppression ou son anonymisation.
            </td>
          </tr>
        </table>

        <p>Vous avez par ailleurs la possibilité de solliciter des informations complémentaires sur les garanties mises
          en œuvre (notamment la copie des conditions contractuelles négociées) pour le transfert de vos données en
          dehors de l’Union Européenne.</p>

        <p>Vous disposez enfin, du droit d'introduire une réclamation auprès de la CNIL, sur <a
            href="https://www.cnil.fr" target="_blank">www.cnil.fr</a>.</p>

        <div class="block-title">
          7. Comment exercer vos droits ?
        </div>

        <p>Vous pouvez contacter le Délégué à la Protection des Données, pour exercer vos droits ou pour toute autre
          demande, par courrier électronique à l’adresse <a href="mailto:dpo@happytal.com" target="_blank">dpo@happytal.com</a>
          ou par courrier en écrivant à l’adresse postale suivante : Happytal – Délégué à la Protection des Données –
          59-61 bis rue Pernety, 75014 Paris.</p>

        <p>Dans un souci de protection de vos données personnelles et en cas de doute sur votre identité, nous pourrons
          vous demander d’en justifier, par exemple en nous adressant la copie d’une pièce d’identité.</p>

        <div class="block-title">
          8. Quel est le rôle du Groupe La Poste dans le traitement de vos données ?
        </div>

        <p>Happytal a rejoint , en juillet 2022, le Groupe La Poste et est désormais une filiale de La Poste Santé
          SA.</p>

        <p>Happytal demeure totalement indépendante quant à la collecte et au traitement de vos données personnelles.
          Aucune de vos données personnelles n’est transmise, pour quelque raison que ce soit, au Groupe La Poste, ni à
          aucune de ses autres filiales.</p>

        <p>Happytal est, en revanche, soumise aux exigences du Groupe La Poste en matière de conformité réglementaire.
          Le respect de la protection des données est une priorité du Groupe La Poste, qui exige, et surveille, le
          respect du RGPD par l’ensemble de ses filiales. Happytal est ainsi entrée dans le périmètre d’intervention du
          DPO du Groupe La Poste. Vous pouvez contacter ce dernier à l’adresse suivante : La Poste - Délégué à la Protection 
          des Données CP Y412 9 rue du Colonel Pierre Avia 75015 PARIS. </p>
      </div>
    </HCard>
  </div>
</template>

<script>
import {
  HCard
} from '@happytal/bo-ui-library'

export default {
  components: {
    HCard
  }
}
</script>
